//colors
$black: #000;
$white: #fff;
$color_alto_approx: #ddd;
$color_black_haze_approx: #f7f7f7;
$color_mountain_mist_approx: #999;
$silver:  silver;
$color_pastel_green_approx: #7e7;
$color_celeste_approx: #ccc;
$blue: #00f;
$color_japanese_laurel_approx: #090;
$color_valencia_approx: #d44;
$color_forest_green_approx: #292;
$color_purple_approx: #708;
$color_blue_gem_approx: #219;
$color_jewel_approx: #164;
$color_endeavour_approx: #05a;
$color_salem_approx: #085;
$color_rich_gold_approx: #a50;
$color_tamarillo_approx: #a11;
$color_international_orange_approx: #f50;
$color_fuscous_gray_approx: #555;
$color_gurkha_approx: #997;
$color_dark_blue_approx: #00c;
$red: red;
$green: #0f0;
$color_torch_red_approx: #f22;
$color_pizazz_30_approx: rgba(255, 150, 0, .3);
$color_solitude_approx: #e8f2ff;
$color_moon_raker_approx: #d7d4f0;
$color_milan_approx: #ffa;
$yellow_40: rgba(255, 255, 0, .4);
$color_pink_swan_approx: #bbb;
$white_100: rgba(255, 255, 255, 1);
$color_pickled_bluewood_approx: #2c3e50;
$color_alabaster_approx: #fcfcfc;
$color_pewter_approx: #95a5a6;
$alabaster: #fafafa;
$color_gallery_approx: #eee;
$color_fruit_salad_approx: #63a35c;
$color_deluge_approx: #795da3;
$color_torea_bay_approx: #183691;
$black_5: rgba(0, 0, 0, .05);
$color_oslo_gray_approx: #7f8c8d;
$color_aluminium_approx: #aab2b3;
$red_15: rgba(255, 0, 0, .15);

//fonts
$font_0: Arial;
$font_1: Helvetica Neue;
$font_2: Helvetica;
$font_3: sans-serif;